import Requests from "@/services/Requests";
import { Currency } from "gurhan/helpers/Currency";

export default {
  data() {
    return {
      services: [],
      selectedServices: [],
      autoServiceSelect: false,
    };
  },
  methods: {
    async getServices() {
      this.isLoading = true;
      const response = await Requests.Room.GetByIdWithIncludes({
        params: {
          id: this.activeRoom.id,
        },
        query: {
          includes: "companyServices",
        },
      });

      if (response.message === "OK") {
        const services = response.data?.includes?.companyServices || [];
        if (!services.length) return this.noServiceMessage();
        else if (services.length === 1) {
          this.isLoading = false;
          this.selectedServices = services.map((service) => service.service.id);
          this.autoServiceSelect = true;
          this.showTextArea = false;
          setTimeout(() => {
            this.sendSelectedServiceMessage();
          }, 500);
        } else if (this.$route.params.serviceIds) {
          const selectedServicesFormParams =
            this.$route.params.serviceIds.split(",");
          const selectedServices = services
            .filter((service) =>
              selectedServicesFormParams.includes(service.id)
            )
            .map((service) => service.id);
          console.log("selectedServices", selectedServices);

          if (selectedServices.length) {
            this.autoServiceSelect = true;
            this.isLoading = false;
            this.selectedServices = selectedServices;
            this.showTextArea = false;
            setTimeout(() => {
              this.sendSelectedServiceMessage();
            }, 500);
          } else this.createMultipleServiceSelectMessage(services);
        } else this.createMultipleServiceSelectMessage(services);

        this.services = services;
      }
    },
    noServiceMessage() {
      this.sendBotMessage(
        this.$t("chat.message.bot.noService", { name: this.company.name }),
        "",
        () => {}
      );
      this.endChat();
    },
    checkboxHtml(id, label, price, suffix = 0) {
      return `
        <div class="c-checkbox u-margin-bottom-small u-margin-top-small u-border u-radius-g u-padding-xsmall">
    <div class="c-checkbox__form-group">
      <input
        class="c-checkbox__form-group__input c-checkbox__form-group__input--opposite"
        type="checkbox"
        id="${id}-${suffix}"
      />
      <label
        class="c-checkbox__form-group__label u-width-100% u-display-inline-block c-checkbox__form-group__label--opposite"
        for="${id}-${suffix}"
        >${label} (${Currency(
        { price: price },
        this.company.currency || "TRY"
      )}) </label
      >
    </div>
  </div>`;
    },
    totalServicePrice() {
      const services =
        this.services.filter((service) =>
          this.selectedServices.includes(service.id)
        ) || [];

      return Currency(
        {
          price: services.reduce((total, service) => total + service.price, 0),
        },
        this.company.currency || "TRY"
      );
    },
    createMultipleServiceSelectMessage(services, suffix = 0) {
      let createHtml = services
        .map((service) =>
          this.checkboxHtml(
            service.id,
            service.service.name,
            service.price,
            suffix
          )
        )
        .join("");
      createHtml += this.$t("chat.message.bot.selectServiceButton", {
        id: "serviceSelectedButton-" + suffix,
      });
      this.sendBotMessage(
        this.$t("chat.message.bot.multipleService", {
          html: createHtml,
          count: services.length,
        }),
        "",
        () => {}
      );
      this.showTextArea = false;

      const vm = this;
      setTimeout(() => {
        document
          .getElementById(`serviceSelectedButton-${suffix}`)
          .addEventListener(
            "click",
            (e) => {
              e.preventDefault();
              vm.selectedServicesMessage(suffix);
            },
            { once: true }
          );
      }, 250);
    },
    checkSelectedServices() {
      const elements = document.querySelectorAll(
        ".c-checkbox__form-group__input"
      );
      const selectedServices = [];
      elements.forEach((element) => {
        const id = element.id.split("-")[0];
        console.log(element.id, id, "iddddididi");

        if (element.checked) selectedServices.push(id);
      });
      return selectedServices;
    },
    selectedServicesMessage(index) {
      const selectedServices = this.checkSelectedServices();

      //eğer servis seçilmez ise;
      if (!selectedServices.length) {
        this.sendBotMessage(
          this.$t("chat.message.bot.noServiceSelected"),
          "",
          () => {}
        );
        return setTimeout(() => {
          const i = index + 1;
          this.createMultipleServiceSelectMessage(this.services, i);
        }, 1500);
      }

      this.selectedServices = selectedServices;

      this.showTextArea = false;
      this.sendSelectedServiceMessage();
    },
    sendSelectedServiceMessage() {
      this.sendBotMessage(
        this.$t("chat.message.bot.selectedService", {
          count: this.selectedServices.length,
          total: this.totalServicePrice(),
        }),
        "",
        () => {}
      );
      setTimeout(() => {
        this.getCustomerPackages();
      }, 4000);
    },
  },
};
